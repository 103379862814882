import React,{useEffect} from 'react';
import compose from 'recompose/compose';
import {inject,observer} from 'mobx-react';
import withRoot from '@src/withRoot';

import './MobileLayout.less';

const MobileBread = (props) => {
  const { data, filterStore } = props;
  const { rawData } = data;
  const { currentFilterType } = filterStore;

  const getFilterTxt = () => {
    if(currentFilterType === 'newDevelopment') {
      return 'Buy New';
    } else if (currentFilterType === 'buyExisting') {
      return 'Buy Existing';
    } else if (currentFilterType === 'rent') {
      return 'Rent';
    } else {
      return '';
    }
  }

  let text1 = '', text2 = '';
  text2 = rawData?.displayName;
  text1 = getFilterTxt();
  const state = rawData?.[':address']?.state;
  const city = rawData?.[':address']?.city;
  if(state) text1 = `${text1} / ${state}`
  if(city) text1 = `${text1} / ${city}`
  if(text2) text1 = `${text1} / `

  return (
    <div className="m-bread-container">
      <span>{text1}</span>
      <span>{text2}</span>
    </div>
  )

}

export default compose(withRoot, inject('filterStore'), observer)(MobileBread);