import React from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'
import { typography } from '../../theme/theme';
import {AUD} from '@src/utils/currency'
import PropertySettingIcons from '@src/components/property/PropertySettingIcons'

const PlanCard = (props) => {
  const {plan,style,onClick} = props
  const styles = useStyles()
  const theme = useTheme()
  // console.log(plan)
  return (
    <div onClick={onClick} className={styles.root} style={style}>
      <div className={styles.top}>
        <div className={styles.end}>
          <Typography className={styles.aspect}>{plan.aspect||'N/A'}</Typography>
        </div>
        <img src={plan.img||plan.services.floorPlan} className={styles.img}/>
      </div>
      <div className={styles.bottom}>
        <Typography className={styles.unitName}>{plan.name}</Typography>
        <Typography className={styles.price}>{plan.showTextForPrice?plan.showTextForPrice:AUD(plan.price).format(true)}</Typography>
        <div className={styles.spaceBetween}>
          <Typography className={styles.size}>{plan.totalSize}㎡</Typography>
          <PropertySettingIcons {...plan} color='#000000'/>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme=>({
  root:{
    borderRadius:5,
    boxShadow:`0px 2px 5px 0px ${theme.palette.grey[400]}`,
    borderWidth:1,
    borderColor:theme.palette.grey[400],
    borderStyle:'solid',
    overflow:'hidden',
  },
  top:{
    padding:16,
    backgroundColor:"white",
    height:170,
    width:'auto',
    overflow:'hidden'
  },
  bottom:{
    padding: '10px 16px',
    backgroundColor:theme.palette.grey[100]
  },
  end:{
    ...hBox('center','flex-end')
  },
  img:{
    width: '100%',
    height: '100%',
    objectFit:'cover'
  },
  spaceBetween:{
    ...hBox('center','space-between')
  },
  aspect:{
    color:theme.palette.grey[500]
  },
  price:{
    ...typography.subtitle2,
    fontWeight:500,
  },
  unitName:{
    fontWeight:400,
    fontSize:16,
  },
  size:{

  }
}))

export default PlanCard
