import React,{useEffect} from 'react';
import compose from 'recompose/compose';
import {inject,observer} from 'mobx-react';
import { Layout } from "antd";
import withRoot from '@src/withRoot';
import { isMobile } from "react-device-detect";

import MobileHeadBar from '@src/components/mobile/MobileHeadBar';
import MobileBread from '@src/components/mobile/MobileBread';
import MobilePropertyDetailPage from '@src/components/mobile/MobilePropertyDetailPage';
import LoginModal from '@src/components/LoginModal';
import PropertyDetailPage from '@src/components/templates/propertyAndProjectPage/PropertyDetailPage';
import LightContainer from '../LightContainer';

const App = (props) => {
  const {
    propertyStore,
    generalPropertyStore,
    uiStore,
    appStore,
    pageContext: { data:passedData }
  } = props
  const data = propertyStore.additionalProcess(generalPropertyStore.processData(passedData))
  const { openLogin, setOpenLogin } = uiStore

  propertyStore.setCurrentDetails(data)
  useEffect(() => {
    uiStore.setExplorePageCurrentTab(null)
  }, []);

  useEffect(() => {
    propertyStore.initPropertyDetailData_(passedData)
  }, [appStore.loggedInUser])
  return (
    isMobile ?
    <Layout>
      <MobileHeadBar />
      <MobileBread data={data}/>
      <MobilePropertyDetailPage data={data} />
      <LoginModal visible={openLogin} handleCancel={() => setOpenLogin(false)} />
    </Layout>
    :
    <LightContainer>
      <PropertyDetailPage pageContextData={passedData}  />
    </LightContainer>
  )
}

export default compose(withRoot, inject('propertyStore', 'generalPropertyStore', 'uiStore', 'appStore'), observer)(App);
