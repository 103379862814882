import React, { useState } from 'react'
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { AUD } from '@src/utils/currency'
import Button from '@material-ui/core/Button';
import { typography } from '@src/theme/theme'
import PropertySettingIcons from '../property/PropertySettingIcons'
import cx from 'classnames'
import { navigate } from "gatsby"
import { hBox, vBox } from "gx-web-ui/utils/flex";
import { inject, observer } from 'mobx-react'
import compose from "recompose/compose";
import { common } from '@material-ui/core/colors';
import { message } from 'antd';
import { leasingApplicationSlug } from '../../utils/slug';

import ShareIconButton from '@src/components/common/ShareIconButton';
import FavorIconButton from '@src/components/common/FavorIconButton';
import PropertyShareModal from '@src/components/property/PropertyShareModal';

const MobileDetailPageSummary = (props) => {
  const [openShare, setOpenShare] = useState(false)
  const { data, uiStore, propertyStore, appStore, listMode = true, unit = false, className } = props
  const styles = useStyles()
  const { setDeveloperDetailPanelOpen, checkLogin, setOpenLogin } = uiStore;
  const {
    favorProperty_,
    unfavorProperty_,
    toggleDetailFavor
  } = propertyStore;
  const {
    name,
    address,
    price,
    propertyType,
    bedRooms,
    banners,
    bedrooms,
    carspaces,
    bathrooms,
    features,
    services,
    showTextForPrice,
    isProject,
    isLeasing,
    internalSize,
    propertyId,
    externalSize,
    totalSize,
    water,
    council,
    strata,
    aspect,
    listingId,
    assignedAgents,
  } = data.processedData

  const {
      loggedInUser
  } = appStore

  const favored = data.processedData.isSaved;
  const onToggleF = (listingId)=>(newState)=>{
    if(newState===true){
        favorProperty_(listingId)
    }else{
        unfavorProperty_(listingId)
    }
    toggleDetailFavor(newState)
  };
  const onToggleFavor= checkLogin(onToggleF(data.processedData.listingId)); 

  const basicInfo = [
    {
      title: 'Price',
      show: true,
      renderItem: showTextForPrice ?
        <Typography className={styles.bodyText}>
          {showTextForPrice}
        </Typography>
        :
        <Typography className={styles.bodyText}>
          {isProject ? 'From ' : ''}<span className={styles.hightLightText}>{AUD(price).format()}</span>
        </Typography>
    },
    {
      title: 'Bedroom',
      show: isProject,
      renderItem:
        <Typography className={styles.bodyText}>
          {`${bedRooms} available`}
        </Typography>
    },
    {
      title: 'Structure',
      show: !isProject,
      renderItem: <PropertySettingIcons
        bedrooms={bedrooms}
        bathrooms={bathrooms}
        carspaces={carspaces}
        color={'#000000'}
        textStyle={{ marginRight: 2 }}
        iconStyle={{ marginRight: 8 }}
      />
    },
    {
      title: 'Property type',
      show: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {propertyType}
        </Typography>
    },
  ]
  const projectInfo = [
    {
      title: 'Address',
      show: true,
      singleLine: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {address.fullText}
        </Typography>
    },
    {
      title: 'Developer',
      show: isProject && data.processedData.developer,
      singleLine: true,
      renderItem:
        <Typography
          onClick={() => {
            setDeveloperDetailPanelOpen(true)
          }}
          className={styles.bodyText}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {data.processedData.developer}
        </Typography>
    },
    {
      title: 'Completion date',
      show: isProject && data.processedData.completeDate,
      singleLine: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {data.processedData.completeDate}
        </Typography>
    },
    {
      title: 'Display centre',
      show: isProject && data.processedData.dispAddress,
      singleLine: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {data.processedData.dispAddress?.fullText}
        </Typography>
    },
    {
      title: 'Project website',
      show: isProject && false,
      singleLine: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {null}
        </Typography>
    },
  ]

  const propertyInfo = [
    {
      title: 'Property ID',
      show: !isProject,
      renderItem:
        <Typography className={styles.bodyText}>
          {propertyId}
        </Typography>
    },
    {
      title: 'Internal size',
      show: !isProject && internalSize,
      renderItem:
        <Typography className={styles.bodyText}>
          {internalSize} m²
        </Typography>
    },
    {
      title: 'External size',
      show: !isProject && externalSize,
      renderItem:
        <Typography className={styles.bodyText}>
          {externalSize} m²
        </Typography>
    },
    {
      title: 'Total size',
      show: !isProject && totalSize,
      renderItem:
        <Typography className={styles.bodyText}>
          {totalSize} m²
        </Typography>
    },
    {
      title: 'Water',
      show: !isProject && water,
      renderItem:
        <Typography className={styles.bodyText}>
          {`${AUD(water).format()} / quarter`}
        </Typography>
    },
    {
      title: 'Strata',
      show: !isProject && strata,
      renderItem:
        <Typography className={styles.bodyText}>
          {`${AUD(strata).format()} / quarter`}
        </Typography>
    },
    {
      title: 'Council',
      show: !isProject && council,
      renderItem:
        <Typography className={styles.bodyText}>
          {`${AUD(council).format()} / quarter`}
        </Typography>
    },
    {
      title: 'Aspect',
      show: !isProject && aspect,
      renderItem:
        <Typography className={styles.bodyText}>
          {aspect}
        </Typography>
    }
  ]


  const dataList = [
    ...basicInfo,
    ...projectInfo,
  ]

  const boxData = [
    ...basicInfo,
    ...propertyInfo,
    ...projectInfo,
  ]

  const unitData = [
    {
      title: 'Property type',
      show: true,
      renderItem:
        <Typography className={styles.bodyText}>
          {propertyType}
        </Typography>
    },
    ...propertyInfo,
    ...projectInfo,
  ]

  const getStatusTxt = (status) => {
    if(status === 'offThePlan') {
      return 'Off the plan';
    } else if (status === 'completed') {
      return 'Completed';
    } else if (status === 'unsold') {
      return 'Available';
    } else if (status === 'offMarket') {
      return 'Off-market';
    } else {
      return '';
    }
  }
  console.log('processData', data.processedData);
  return (
    <div className={cx(styles.boxRoot, className)}>
      <div className={styles.priceShareFavor}>
        {showTextForPrice ?
          <Typography className={styles.bodyText}>
            {showTextForPrice}
          </Typography>
          :
          <Typography className={styles.bodyText}>
            {isProject ? 'From ' : ''}<span className={styles.hightLightText}>{AUD(price).format()}</span>
          </Typography>
        }
        <div>
          <ShareIconButton size={25} className={styles.iconButton} color='#A1A1A1' onClick={()=>setOpenShare(true)}/>
          {onToggleFavor && <FavorIconButton onToggleSave={onToggleFavor} inactiveColor={'#A1A1A1'} className={styles.iconButton} isSaved={favored} size={25}/>}
        </div>
      </div>
      <Typography className={styles.bodyText2}>
        {address.fullText}
      </Typography>
      <Typography className={styles.bodyText}>
        {`${bedRooms} available`}
      </Typography>
      <Typography className={styles.bodyText}>
        {getStatusTxt(data?.rawData?.status)}
      </Typography>
      <PropertyShareModal open={openShare} onClose={()=>setOpenShare(false)}/>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: '100%',
    padding: '20px 20px 0'
  },
  priceShareFavor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconButton:{
    textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)',
    marginLeft:12,
    marginRight:8,
  },
  item: {
    width: "50%",
    ...hBox('center'),
    marginTop: 2,
    marginBottom: 2
  },
  boxTitle: {
    width: 150,
    ...typography.subtitle1,
    color: theme.palette.grey[500],
  },
  root: {
    position: 'relative',
    minWidth: 220,
    marginLeft: 16,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  bodyText: {
    ...typography.body1,
    fontWeight: "600",
  },
  bodyText2: {
    ...typography.body1,
    fontWeight: "400",
  },
  hightLightText: {
    color: theme.palette.clientPrimary.main,
  },
  listStyle: {
    listStyle: 'none',
    marginTop: 8,
    marginBottom: 8
  },
  ulStyle: {
    paddingLeft: 0
  },
  divider: {
    marginTop: 8,
    height: 0.5,
    width: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  title: {
    ...typography.body1,
    color: theme.palette.grey[500]
  },
  alignH: {
    ...hBox('center')
  },
  applyButton: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    color: theme.palette.getContrastText(common["black"]),
    backgroundColor: common["black"],
    '&:hover': {
      backgroundColor: common["black"],
    },
  }
}))

export default compose(
  inject('uiStore', 'propertyStore', 'appStore'),
  observer
)(MobileDetailPageSummary)
