import React, { useState } from 'react';
import compose from 'recompose/compose';
import {inject,observer} from 'mobx-react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { isMobile } from "react-device-detect";
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

import MobileIndexStore from '@src/components/mobile/MobileIndexStore';
import MobileFooter from '@src/components/mobile/MobileFooter';
import BookPrivateInspectionModal from '@src/components/mobile/BookPrivateInspectionModal';
import MobileDetailPageBanner from './MobileDetailPageBanner';
import MobileDetailPageSummary from './MobileDetailPageSummary';
import DetailPageDescription from '@src/components/property/DetailPageDescription';
import DetailPageFeatures from '@src/components/property/DetailPageFeatures';
import DetailPageLocation from '@src/components/property/DetailPageLocation';
import DetailPageInspection from '@src/components/property/DetailPageInspection';
import DetailPageSchoolInformation from '@src/components/property/DetailPageSchoolInformation';
import DetailPageAgents from '@src/components/property/DetailPageAgents';
import MobileDetailPageFloorPlan from './MobileDetailPageFloorPlan';
import DetailPageConstructionUpdate from '@src/components/property/DetailPageConstructionUpdate';
import PropertyCalculator from '@src/components/property/PropertyCalculator';
import { AUD } from '@src/utils/currency';

import FavorateIcon from '../../images/favorate.png';

import './MobileLayout.less';

const MobilePropertyDetailPage = (props) => {
  const [openPrivate,setOpenPrivate] = useState(false)
  const onPressBookPrivate = ()=>{
    setOpenPrivate(true)
  }

  const { data, uiStore, propertyStore, appStore } = props;
  const {isProject,assignedAgents,units,constructionUpdates,listingId,isLeasing} = data.processedData;
  const {checkLogin, setIsMessagePanelVisible, isMessagePanelVisible, setMessageReceiverId, setSentPropertyListing} = uiStore;
  const {getBrochureUrl} = propertyStore
  const styles = useStyles()
  const theme = useTheme()
  let phone = '', agent = null;
  if(assignedAgents.length > 0) {
    agent = assignedAgents[0];
    phone = assignedAgents[0]?.mobile;
  }

  const leftContents = [
    {
      show:isProject,
      component:<DetailPageFeatures data={data}/>
    },
    {
      show:true,
      component:<DetailPageDescription data={data}/>,
      hideDivider:true,
    },
    // {
    //   show:isProject && units.filter(item=>Boolean(item.rawData.isDisplayUnit)).length > 0,
    //   component:<MobileDetailPageFloorPlan units={units} projectRawData={data.rawData} />
    // },
    {
      show:isProject && constructionUpdates.length>0,
      component:<DetailPageConstructionUpdate constructionUpdates={constructionUpdates}/>
    },
    {
      show:!isProject && data.processedData.features.length > 0,
      component:<DetailPageFeatures data={data}/>
    },
    {
      show:true,
      component:<DetailPageLocation data={data}/>
    },
    {
      show:isMobile,
      component:<DetailPageAgents agents={assignedAgents}/>
    },
    {
      show:true,
      component:<DetailPageInspection data={data}/>
    },
    {
      show:true,
      component:<DetailPageSchoolInformation coordinate={data.processedData.coordinate}/>
    },
    {
      show:!isProject && !isLeasing,
      component:<PropertyCalculator data={data}/>
    },
  ]

  const generatePropertyCard = () => {
    const details = data.processedData
    console.log('tag', data.processedData)

    const propertyCard = isProject ?
    {
      project: {
        id: details.projectId,
        thumbnail: details.thumbnail,
        address: details.address.fullText,
        type: details.propertyType[0] || '',
        price: details.showTextForPrice || (details.price ? `Price from ${AUD(details.price).format(true)}` : ''),
        name: details.name,
      }
    }
    :
    {
      property: {
        id: details.propertyId,
        thumbnail: details.thumbnail,
        address: details.address.fullText,
        type: details.propertyType,
        price: details.showTextForPrice || (details.price ? `${AUD(details.price).format(true)}` : ''),
        bedrooms: details.bedrooms,
        bathrooms: details.bathrooms,
        carspaces: details.carspaces,
      }
    }

    return propertyCard
  }

  const onSendMessage= async (receiverId) => {
    try {
      const card = generatePropertyCard();
      let res = await appStore.client.post('message', {msg: card, receiverId: receiverId});
      setTimeout(() => {
        appStore.clientApi.sendListingEnquiry_(listingId, agent?.id);
      }, 50);
    } catch (e) {
      console.log(e);
    }
  }

  const sendMessage = () => {              
    checkLogin(async () => {
      if (!isMessagePanelVisible) {
        if (onSendMessage) {
            await onSendMessage(agent[':user'].id)
        }
        setSentPropertyListing(true)
        setMessageReceiverId(agent[':user'].id)
        window.scroll({top: 0, left: 0, behavior: 'smooth'})
      }
      setIsMessagePanelVisible(!isMessagePanelVisible)
    })()
  }

  const onClickBrochure=()=>{
    window.open(getBrochureUrl(listingId),'_blank')
  }

  return (
    <div>
      <div style={{ width: '100%', background: 'white' }}>
        <MobileDetailPageBanner data={data} onClick={()=>{}} height={260}/>
        <MobileDetailPageSummary data={data} className={styles.listMode} listMode={false}/>
        <div style={{margin: '0 20px'}}>
          <div className={styles.divider}/>
          <div className={styles.toolkits}>
            <div className={styles.toolkitsItem} onClick={() => window.location.href=`tel:${phone}`}>
              <div className={styles.toolkitsCircle}>
                <PhoneEnabledIcon style={{color: '#E56E2C', fontSize: 17 }}/>
              </div>
              <div className={styles.toolkitsTip}>Call</div>
            </div>
            <div className={styles.toolkitsItem} onClick={sendMessage}>
              <div className={styles.toolkitsCircle}>
                <TextsmsOutlinedIcon style={{color: '#E56E2C', fontSize: 17 }}/>
              </div>
              <div className={styles.toolkitsTip}>Chat</div>
            </div>
            <div className={styles.toolkitsItem} onClick={onPressBookPrivate}>
              <div className={styles.toolkitsCircle}>
                <CalendarTodayOutlinedIcon style={{color: '#E56E2C', fontSize: 17 }}/>
              </div>
              <div className={styles.toolkitsTip}>Inspection</div>
            </div>
            <div className={styles.toolkitsItem}>
              <div className={styles.toolkitsCircle}>
                <img src={FavorateIcon} className={styles.toolkitsIcon} />
              </div>
              <div className={styles.toolkitsTip}>Favourite</div>
            </div>
          </div>
          <div className={styles.divider}/>
          {/* {isProject&&<DetailPageFeatures data={data}/>}
          <div className={styles.divider}/> */}
          <DetailPageDescription data={data}/>
          <div className={styles.divider}/>
          {isProject && units.filter(item=>Boolean(item.rawData.isDisplayUnit)).length > 0&&
            <div className='m-floorplan'>
              <MobileDetailPageFloorPlan units={units} projectRawData={data.rawData} />
              <div className={styles.divider}/>
            </div>
          }
          <DetailPageLocation data={data} defaultHeight={222}/>
          <div className={styles.divider}/>
          <DetailPageSchoolInformation coordinate={data.processedData.coordinate}/>
          <div className={styles.divider}/>
          <div>
            <div className={styles.contactTitle}>Contact Agent</div>
            <div className={styles.contactBtn} onClick={onPressBookPrivate}>Request Inspection</div>
          </div>
        </div>
        <MobileIndexStore style={{marginTop: '50px'}}/>
        <MobileFooter />
        <BookPrivateInspectionModal open={openPrivate} onClose={()=>setOpenPrivate(false)}/>
      </div>
    </div>
  )
}
const useStyles = makeStyles(theme=>({
  bannerLine:{
    ...hBox('stretch')
  },
  listMode:{
    maxHeight:600
  },
  toolkits: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolkitsItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolkitsCircle: {
    width: '25px',
    height: '25px',
    border: '1px solid #E56E2C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  toolkitsIcon: {
    width: '17px',
    height: '17px'
  },
  toolkitsTip: {
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '26px',
    color: '#E56E2C',
    marginTip: '5px'
  },
  floorPlan: {
    // padding: '20px 0'
  },
  contactTitle: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#000000',
  },
  contactBtn: {
    marginTop: '20px',
    width: '100%',
    height: '50px',
    lineHeight: '50px',
    border: '1px solid #E56E2C',
    color: '#E56E2C;',
    fontWeight: '700',
    borderRadius: '4px',
    textAlign: 'center'
  },
  mIndexStore: {
    marginTop: '50px'
  },
  margin:{
    paddingTop:16,
    paddingBottom:16
  },
  content:{
    ...hBox('top'),
    width:'100%'
  },
  leftContent:{
    // flex:1,
    marginRight:16
  },
  rightContent:{
    minWidth:380,
    position:'relative'
  },
  divider:{
    height:1,
    width:'100%',
    backgroundColor:theme.palette.grey[300],
    marginTop: '20px',
    marginBottom: '20px'
  },
  downloadBrochure:{
    paddingTop:2,
    paddingBottom:2,
    marginTop:16,
  },
  sticky:{
      ...vBox('right')
  }
}))

export default compose(inject('uiStore', 'propertyStore', 'appStore'), observer)(MobilePropertyDetailPage)
