import React,{useState,useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {motion} from 'framer-motion'

import ButtonBase from '@material-ui/core/ButtonBase';
import { observer, inject } from 'mobx-react';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import compose from 'recompose/compose'
import FavorIconButton from '@src/components/common/FavorIconButton'
import NoteIconButton from '@src/components/common/NoteIconButton'
import ShareIconButton from '@src/components/common/ShareIconButton'
import PropertyCardNote from '@src/components/property/PropertyCardNote'
import {ReactComponent as ThreeD} from '@src/assets/svgIcons/3D.svg'
import {ReactComponent as Play} from '@src/assets/svgIcons/Play.svg'
import FloorPlan from '@src/components/icons/FloorPlan'
import { navigate, useLocation } from '@reach/router'
import findIndex from 'lodash/findIndex'
//import ReactPlayer from 'react-player'
import PropertyCarousel from '@src/components/property/PropertyCarousel'
import PropertyShareModal from '@src/components/property/PropertyShareModal'
import { Button, Popover, Typography as ATypography, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import trim from 'lodash/trim'

import "react-responsive-carousel/lib/styles/carousel.min.css";
const variants = {
    visiable:{
        opacity:1
    },
    invisiable:{
        opacity:0,
        transitionEnd:{
            display:'none'
        }
    }
}

const DetailPageBannerInfoCover = compose(
    inject('uiStore'),
    observer
)(props=>{
    const { 
        uiStore,
        children,
        favored,
        data,
        onToggleFavor,
        collectionDetails,
        currentIndex,
        setCurrentIndex,
        videoBannerIndex
     } = props
    const {checkLogin} = uiStore
    const {thumbnail, name, address, price, propertyType, bedRooms,banners, features, services, showTextForPrice, isProject } = data.processedData
    const styles = useCoverStyles()
    const [openNote, setOpenNote] = useState(false)
    const [openShare, setOpenShare] = useState(false)
    const noteBtnRef = useRef(null)
    const hasVideo = videoBannerIndex >= 0
    const bottomRightActions = [
        {
            key:'floorPlan',
            Icon:FloorPlan,
            onClick:()=>{
                const floorPlanIndex = findIndex(banners,{url:services.floorPlan})
                if(floorPlanIndex){
                    setCurrentIndex(floorPlanIndex)
                }
            },
            disabled:!Boolean(services.floorPlan),
        },
        {
            key:'play',
            Icon:Play,
            onClick:()=>{
                if(videoBannerIndex){
                    setCurrentIndex(videoBannerIndex)
                }  
            },
            disabled:!hasVideo,
        },
    ]

    const popoverContent = (
        services?.VRList?.map(({ url, title }, idx) => (
            <ATypography.Link key={idx} href={url} target="_blank">
                <Row justify="space-between" align="middle">
                    <span>{title || `VR-${idx}`}</span>
                    <RightOutlined />
                </Row>
            </ATypography.Link>
        ))
    );
    
    return  <div style={{boxShadow: 'rgb(189 189 189) 0px 4px 10px 0px;'}}>
      {isProject && 
        <div className={styles.header}>
          <Avatar src={thumbnail} className={styles.image}/>
          <Typography className={styles.headerText}><strong style={{fontWeight:400}}>{name}</strong>· {address.city}</Typography>
        </div>
      }
      {children}
      <div className={styles.root}>
        {/* {isProject && 
        <div className={styles.header}>
            <Avatar src={thumbnail} className={styles.image}/>
            <Typography className={styles.headerText}><strong style={{fontWeight:400}}>{name}</strong>· {address.city}</Typography>
        </div>
        } */}
        {collectionDetails && <PropertyCardNote open={openNote} anchorRef={noteBtnRef} onClose={()=>setOpenNote(false)} collectionDetails={collectionDetails}/>}
        {/* {children} */}
        <motion.div variants={variants} className={styles.gradient} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?'invisiable':'visiable'}/>
        <motion.div variants={variants} className={styles.gradientTop} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?'invisiable':'visiable'}/>
        <div className={styles.topRightActions}>
            <ShareIconButton size={25} className={styles.iconButton} color='white' onClick={()=>setOpenShare(true)}/>
            {collectionDetails && favored && <NoteIconButton hasNote={collectionDetails.note!==null && collectionDetails.note!==''} onClick={checkLogin(()=>setOpenNote(!openNote))} className={styles.iconButton} ref={noteBtnRef}/>}
            {onToggleFavor && <FavorIconButton onToggleSave={onToggleFavor} className={styles.iconButton} isSaved={favored} size={25}/>}
        </div>
        <motion.div variants={variants} className={styles.bottomRightActions} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?'invisiable':'visiable'}>
            {bottomRightActions.map(({Icon,onClick,disabled,key})=>(
                <ButtonBase key={key} disableRipple disabled={disabled} onClick={onClick}>
                    <Icon height={25} width={25} fill={disabled?'rgba(255,255,255,0.5)':'white'} className={styles.iconButton}/>
                </ButtonBase>
            ))}

            {
                services.VRList && services.VRList.length > 1
                ?
                <Popover content={popoverContent} title="VR List" trigger="click" overlayInnerStyle={{ maxHeight: 200, width: 240, overflowY: 'scroll' }}>
                    <ThreeD height={25} width={25} fill="white" className={styles.iconButton}/>
                </Popover>
                :
                <ButtonBase disableRipple disabled={!Boolean(services.VR)} onClick={() => window.open(services.VR, "blank")}>
                    <ThreeD height={25} width={25} fill={!Boolean(services.VR)?'rgba(255,255,255,0.5)':'white'} className={styles.iconButton}/>
                </ButtonBase>
            }
        </motion.div>
        <motion.div variants={variants} className={styles.counter} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?'invisiable':'visiable'} >
            <Typography variant='subtitle1' className={styles.title}>{currentIndex+1}  /  {banners.length}</Typography>
        </motion.div>
        <PropertyShareModal open={openShare} onClose={()=>setOpenShare(false)}/>
      </div>
    </div>
})

const useCoverStyles = makeStyles(theme=>({
    root: {
        flex: 1,
        position: 'relative',
        overflow:'hidden'
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 25%)',
        pointerEvents:'none'
    },
    gradientTop:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 20%, rgba(0,212,255,0) 100%)',
        pointerEvents:'none'
    },
    header:{
        ...hBox('center'),
        pointerEvents:'none',
        background: 'white',
        padding: '0 10px'
    },
    headerText:{
        // ...typography.body1,
        fontSize:16,
        fontWeight:300,
        // color:'white'
    },
    image:{
        width:40,
        height:40,
        resizeMode: 'cover',
        marginRight:8,
    },
    topRightActions:{
        position:'absolute',
        top:16,
        right:8,
        ...hBox('center'),
    },
    iconButton:{
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)',
        marginLeft:12,
        marginRight:8,
    },
    bottomRightActions:{
        position:'absolute',
        bottom:16,
        right:8,
        ...hBox('center'),
    },
    counter:{
        position:'absolute',
        bottom:16,
        left:16,
        ...hBox('center'),
    },
    title:{
        color:'white',
    }
}))

const MobileDetailPageBanner = (props) => {
    const {
        data,
        onClick,
        height=260,
        propertyStore,
        uiStore
    } = props
    const {
        favorProperty_,
        unfavorProperty_,
        toggleDetailFavor
    } = propertyStore
    const styles = useStyles()
    const [currentIndex, setCurrentIndex] = useState(0)
    // const images = data.processedData.banners.map(banner => ({ url: banner.url }));
    const location = useLocation()
    const {checkLogin} = uiStore

    const onToggleFavor = (listingId)=>(newState)=>{
        if(newState===true){
            favorProperty_(listingId)
        }else{
            unfavorProperty_(listingId)
        }
        toggleDetailFavor(newState)
    }

    const videoBannerIndex = findIndex(data.processedData.banners,'video')
    return (
        <div className={styles.root}>
            <div style={{...(onClick && {cursor:'pointer'})}}>
                <DetailPageBannerInfoCover
                    favored={data.processedData.isSaved} 
                    data={data} 
                    onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId))} 
                    collectionDetails={data.collectionDetails}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    videoBannerIndex={videoBannerIndex}
                >
                    <PropertyCarousel 
                        banners={data.processedData.banners}
                        setCurrentIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        height={height}
                        onClickImage={()=>window.open(`${trim(location.href,'/')}/gallery`,'_black')}
                    />
                </DetailPageBannerInfoCover>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        userSelect:'none',
        borderRadius:5,
        overflow:'hidden',
        width: '100%'
    },
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 20px)',
        width:40,
        height:40,
        cursor:'pointer',
        fontSize: 70,
        color: 'white',
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)'
    },
    img:{
        objectFit:'cover'
    }
}))

export default compose(
    inject('uiStore','propertyStore'),
    observer
)(MobileDetailPageBanner)
