import React ,{useRef,useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'
import PlanCard from  './PlanCard'
import UnitModal from  '@src/components/property/UnitModal'
import ItemsCarousel from 'react-items-carousel';

const Arrow = props=>{
  const {title,style} = props
  const styles = useStyles()
  return <div className={styles.btn} style={style}>
    {title}
  </div>
}

const DetailPageFloorPlan = (props) => {
  const {units, projectRawData} = props
  const styles = useStyles()
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const [selectedUnit, setSelectUnit] = useState(null)
  const onClick=(plan)=>{
    setSelectUnit(plan)
    setOpen(true)
  }
  return (
    <div style={{maxWidth:900}}>
      <Typography className={styles.title}>Floor plan</Typography>
      <div style={{"padding":0,"maxWidth":"100%","margin":"0"}}>
        <ItemsCarousel
          infiniteLoop={false}
          gutter={0}
          activePosition={'center'}
          chevronWidth={60}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={3}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither={false}
          firstAndLastGutter={true}
          activeItemIndex={value}
          requestToChangeActive={value => setValue(value)}
          rightChevron={<Arrow title={'>'} style={{transform:'translateX(22px)'}}/>}
          leftChevron={<Arrow title={'<'} style={{transform:'translateX(-22px)'}}/>}
        >
        {units.filter(item=>Boolean(item.rawData.isDisplayUnit)).map(plan=>
          <PlanCard onClick={()=>onClick(plan)} plan={plan.processedData} style={{margin:8, width: 172, height: 259}} key={plan.processedData.propertyId}/>
        )}
        </ItemsCarousel>
      </div>
      <UnitModal open={open} onClose={()=>setOpen(false)} projectRawData={projectRawData} unitData={selectedUnit}/>
    </div>
  )
}

const useStyles = makeStyles(theme=>({
  title:{
    fontWeight:700,
    fontSize:16
  },
  btn:{
    ...hBox('center','center'),
    backgroundColor:'white',
    borderRadius:'50%',
    boxShadow:`0px 2px 5px 0px ${theme.palette.grey[400]}`,
    height:40,
    width:40,
  }
}))

export default DetailPageFloorPlan
