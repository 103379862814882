import React,{useState,useEffect} from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import Fade from '@material-ui/core/Fade';
import {ReactComponent as Cross} from '@src/assets/svgIcons/Cross.svg'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PropertySettingIcons from '@src/components/property/PropertySettingIcons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DateTime } from 'luxon'
import BlackBtn from '@src/components/common/BlackBtn'
import { AUD } from '@src/utils/currency';
import { ReactComponent as Confirm } from '@src/assets/svgIcons/Confirm.svg'
import { ReactComponent as Location } from '@src/assets/svgIcons/Location.svg'
import { ReactComponent as Time } from '@src/assets/svgIcons/Time.svg'
import { ReactComponent as Account } from '@src/assets/svgIcons/Account.svg'
import join from 'lodash/join'
import CustomBackdrop from 'commons/components/CustomBackdrop';


import DateFnsUtils from '@date-io/date-fns';
import { 
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
 } from '@material-ui/pickers';

const CustomBtn = withStyles(theme=>({
  outlined:{
    borderColor:theme.palette.clientPrimary.main,
    textTransform:'none',
    color:theme.palette.clientPrimary.main,
    width:'100%'
  }
}))(Button)


const BookPrivateInspectionModal = (props) => {
  const { onClose,open,propertyStore,inspectionStore } = props
  const { bookPrivateInspection_ } = inspectionStore
  const { currentDetails } = propertyStore
  const styles = useStyles()
  const theme = useTheme()
  const [selectedDate, handleDateChange] = useState(null);
  const [startTime, handleStartTimeChange] = useState(null);
  const [endTime, handleEndTimeChange] = useState(null);
  const [succeed, setSucceed] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(open===false){
      setTimeout(() => {
        handleDateChange(null)
        handleStartTimeChange(null)
        handleEndTimeChange(null)
        setSucceed(false)
        setLoading(false)
      }, 300);
    }
  }, [open])
  // console.log(currentDetails)
  const processedData = currentDetails.processedData
  const assignedAgents = processedData.assignedAgents
  const EndsError = endTime && startTime && DateTime.fromJSDate(endTime).toMillis() < DateTime.fromJSDate(startTime).toMillis()
  const lists = [
    {
      label:'Choose a date',
      component:<div>
        <DatePicker 
          value={selectedDate} 
          onChange={handleDateChange} 
          inputVariant="outlined"
          margin='dense'
          fullWidth
          disablePast
        />
      </div>
    },
    {
      label:null,
      component:<div className={styles.alignTop}>
        <div className={styles.timeContent}>
          <Typography className={styles.label}>Starts</Typography>
          <TimePicker 
            value={startTime} 
            onChange={handleStartTimeChange} 
            inputVariant="outlined"
            margin='dense'
            minutesStep={5}
            fullWidth
            format='HH:mm a'
          />
        </div>
        <div className={styles.timeContent} style={{marginLeft:16}}>
          <Typography className={styles.label} style={{color:EndsError?theme.palette.error.main:null}}>Ends</Typography>
          <TimePicker 
            error={EndsError}
            value={endTime} 
            onChange={handleEndTimeChange} 
            inputVariant="outlined"
            margin='dense'
            minutesStep={5}
            fullWidth
            format='HH:mm a'
            helperText={EndsError && 'The end time must be later than the start time.'}
          />
        </div>
      </div>
    }
  ]

  const summaries=[
    {
      Icon:Time,
      component:<Typography><strong>{DateTime.fromJSDate(startTime).toFormat('hh:mm a')}</strong> - <strong>{DateTime.fromJSDate(endTime).toFormat('hh:mm a')}</strong> {DateTime.fromJSDate(selectedDate).toFormat('dd/MM/yyyy')}</Typography>
    },
    {
      Icon:Location,
      component:<Typography>{processedData.address.fullText}</Typography>
    },
    {
      Icon:Account,
      component:<Typography>Your booking request has been sent to:<strong>{join(assignedAgents.map(agent=>agent.preferredName ||agent[':user'].name),',')}</strong>.</Typography>
    },
  ]
  
  const onClickBook = async ()=>{
    setLoading(true)
    await bookPrivateInspection_(
      currentDetails.processedData.listingId,
      DateTime.fromJSDate(selectedDate).toISO(),
      DateTime.fromJSDate(startTime).toISO(),
      DateTime.fromJSDate(endTime).toISO(),
    )
    setLoading(false)
    setSucceed(true)
  }
  
  const isDisabled = selectedDate ===null || startTime===null || endTime === null || EndsError
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Modal
        open={open}
        onClose={onClose}
        className={styles.modal}
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={open}>
          <div className={styles.root}>
            <IconButton className={styles.cross} onClick={onClose}>
              <Cross height={12} width={12} fill={theme.palette.grey[700]}/>
            </IconButton>
            <div className={styles.paper} style={{width:succeed?'57vw':'100vw'}}>
              {succeed?
                <div className={styles.content}>
                  <div className={styles.alignV}>
                    <Confirm 
                      fill={theme.palette.clientPrimary.main} 
                      height={55} 
                      width={55} 
                      className={styles.icon}
                    />
                    <Typography className={styles.confirmTitle}>Booking confirmed!</Typography>
                    <Typography className={styles.subtitle}>Added to your calendar</Typography>
                  </div>
                  <div className={styles.summary}>
                    <Typography className={styles.bookingDetail}>Your booking details:</Typography>
                    {summaries.map(({Icon,component},index)=>
                    <div className={styles.summaryLine} key={index}>
                      <Icon height={20} width={20} fill={theme.palette.grey[600]} style={{marginRight:8}}/>
                      {component}
                    </div>
                    )}
                  </div>
                  <CustomBtn variant='outlined' onClick={onClose} style={{marginTop:8}}>
                    Done
                  </CustomBtn>
                </div>
                :
                <div className={styles.content}>
                  <Typography className={styles.title}>Book a private inspection</Typography>
                  <div className={styles.propertyCard}>
                    <img src={processedData.thumbnail} className={styles.img}/>
                    <div className={styles.cardDesc}>
                      <div>
                        <Typography className={styles.address}>{processedData.address.fullText}</Typography>
                        <Typography className={styles.price}>{AUD(processedData.price).format(true)}</Typography>
                      </div>
                      <PropertySettingIcons {...processedData} color={'black'}/>
                    </div>
                  </div>
                  {lists.map(({label,component},index)=>
                  <div className={styles.line} key={index}>
                    {label && <Typography className={styles.label}>{label}</Typography>}
                    {component}
                  </div>
                  )}
                  <BlackBtn fullWidth className={styles.bookBtn} onClick={onClickBook} disabled={isDisabled}>
                    {loading?
                    <CircularProgress color='secondary' size={24}/>
                    :
                    'Book'
                    }
                  </BlackBtn>
                </div>
              }
            </div>
          </div>
        </Fade>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root:{
    position:'relative',
    borderRadius:5,
    overflow:'hidden'
  },
  paper:{
    backgroundColor:'white',
    borderRadius:4,
    minHeight:300,
    // maxHeight:650,
    // minWidth:300,
    width:700,
    //padding:theme.spacing(2),
  },
  divider:{
    height:1,
    width:'100%',
    backgroundColor:theme.palette.grey[300],
    marginTop:16,
    marginBottom:16,
  },
  modal:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cross:{
    position:'absolute',
    top:4,
    right:4,
    zIndex:2,
  },
  line:{
    marginTop:16
  },
  label:{
    fontSize:14,
    color:theme.palette.grey[600]
  },
  content:{
    padding:24
  },
  bookBtn:{
    marginTop:16,
  },
  propertyCard:{
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'black',
    borderRadius:5,
    padding:8,
    ...hBox('stretch'),
  },
  alignH:{
    ...hBox('center')
  },
  timeContent:{
    width:'100%'
  },
  title:{
    fontWeight:600,
    fontSize:18,
    marginBottom:16
  },
  img:{
    height:90,
    width:90
  },
  cardDesc:{
    ...vBox('left','space-between'),
    marginLeft:16
  },
  address:{
    fontSize:16,
    fontWeight:600,
  },
  price:{
    fontSize:16,
    color:theme.palette.clientPrimary.main,
    fontWeight:500,
  },
  alignV:{
    ...vBox('center')
  },
  icon:{
    marginTop:16,
    marginBottom:16,
  },
  confirmTitle:{
    fontWeight:600,
    fontSize:18,
  },
  subtitle:{
    fontSize:14,
    color:theme.palette.grey[600]
  },
  summary:{
    marginTop:8
  },
  summaryLine:{
    ...hBox('top'),
    marginTop:8
  },
  bookingDetail:{
    fontWeight:500
  },
  alignTop:{
    ...hBox('top')
  }
}));

export default compose(inject('propertyStore', 'inspectionStore'), observer)(BookPrivateInspectionModal);
